<template>
  <div class="dictionary">
    <div class="title">
      <span>其他配置 </span>
    </div>
    <el-tabs type="border-card" class="tabs_div" v-model="name" @tab-click="handleClick">
       <el-tab-pane label="直播配置" name="liveConfig">
        <liveConfig ref="liveConfig" ></liveConfig>
      </el-tab-pane>
      <el-tab-pane label="咨询热线" name="consultHotline">
        <consultHotline ref="consultHotline" ></consultHotline>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import consultHotline from "./consultHotline/index";
  import liveConfig from "./liveConfig/index";
  export default {
    components: {
      consultHotline,
      liveConfig,
    },
    data() {
      return {
        name: "liveConfig",
      };
    },
    computed:{
       poewrMenuIdList() {
        return this.$store.state.poewrMenuIdList?this.$store.state.poewrMenuIdList:[]
      },
    },
    methods: {
      handleClick(tab, event) {
        this.$refs[tab.name].getList();
       
      },
    },
  };
</script>

<style lang="less" scoped>
  .dictionary {
    /deep/.el-tabs__item {
      width: 152px;
      text-align: center;
    }
  }
</style>