<template>
  <div class="consultHotline">
    <!-- <el-card class="condition_style2"> -->
    <div class="flex">
      <div class="add">
        <el-button type="primary" size="small" @click="add('')">新增</el-button>
      </div>
      <!-- <el-form :inline="true" class="flex"  :model="searchForm" >
          <el-form-item>
            <el-select v-model="searchForm.typeId" placeholder="请选择所属分类">
              <el-option v-for="(item, index) in typeList" :key="index" :label="item.typeName" :value="item.typeId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="searchForm.status" placeholder="请选择状态">
              <el-option v-for="(item, index) in startList" :key="index" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="searchForm.title" placeholder="请输入平台名称" suffix-icon="el-icon-search"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
          </el-form-item>
        </el-form> -->
    </div>
    <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
      <el-table-column type="index" label="序号" width="60" align="center" fixed>
        <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
      </el-table-column>
      <el-table-column prop="mchName" label="平台名称" min-width="150" align="center"></el-table-column>
      <el-table-column prop="value" label="咨询热线" min-width="200" align="center" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="adaptorType" label="所属分类" min-width="120" align="center">
        <template slot-scope="scope">
          {{scope.row.adaptorType==3?"学生":"家长"}}
        </template>
      </el-table-column>
      <!-- <el-table-column prop="status" label="当前状态" min-width="100" align="center">
          <template slot-scope="scope">
            <span class="dot" :class="stateClass(scope.row.status)"></span> {{showState(scope.row.status)}}
          </template>
        </el-table-column> -->
      <el-table-column prop="updateTime" label="最后操作时间" min-width="200" align="center"></el-table-column>
      <el-table-column prop="updateBy" label="最后操作人" min-width="100" align="center"></el-table-column>
      <el-table-column fixed="right" label="操作" width="130" align="center">
        <template slot-scope="scope">
          <div class="flex_just_center">
            <el-button type="text" size="small" @click="add(scope.row.id)">编辑
            </el-button>
            <el-button type="text" size="small" @click="changeState(scope.row,false)">删除 </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <pagination :page="current" :size="size" :total="total" @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"></pagination>
    <!-- </el-card> -->
    <!-- 新增科普音频 -->
    <modal :obj="{
        title: edit ? '编辑' : '新增',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
        okText: '确认',
        cancelText: '取消',
        width:'750px'
      }" @submit="addSubmit">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="平台名称：" prop="typeId">
          <el-select v-model="addForm.mchId" class="w_100" placeholder="请输入平台名称">
            <el-option v-for="(item, index) in maList" :key="index" :label="item.mchName" :value="item.mchId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属板块：" prop="adaptorType">
          <el-select class="w_100" v-model="addForm.adaptorType" placeholder="请选择所属板块">
            <el-option v-for="(item, index) in blackList" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="咨询热线：" prop="value">
           
          <el-input v-model="addForm.value"  @input="addForm.value=$util.noEnNum(addForm.value)" maxlength="11" placeholder="请输入咨询热线" />
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "../../../components/pagination";
  import modal from "../../../components/modal";
  import {
    configSave,
    configUpdate,
    configInfo,
    merchantList,
    getConfigList,
    sysConfigDel,
  } from "../../../api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        tableHeight: this.$util.getHeight(310),
        addDialog: false,
        size: 10, //每页条数
        current: 1, //当前条数
        total: 0,
        searchForm: {
          type: "2",
        },
        addForm: {
          mchId: "",
          value: "",
          adaptorType: 4,
          type: 2 //1:直播;2:咨询热线
        },
        maList: [],
        tableData: [],
        blackList: [{
          id: 3,
          name: '学生'
        }, {
          id: 4,
          name: '家长'
        }],
        showBtn: false,
        rules: {
          configValue: [{
            required: true,
            message: "请选择房间号"
          }],

        },
        edit: "",
        typeList: [],
        startList: [{
          value: null,
          label: "全部",
        }, {
          value: "0",
          label: "上架",
        }, {
          value: "1",
          label: "下架",
        }, {
          value: "2",
          label: "待上架",
        }],
      };
    },
    computed: {},
    watch: {

    },
    created() {
      this.getList();
      this.getMerchantList()
    },
    mounted() {
      window.addEventListener('resize', () => {
        let num = this.showBtn ? 260 : 300
        this.getTableHeight(num)
      })
    },
    methods: {
      getTableHeight(val) {
        this.tableHeight = this.$util.getHeight(val)
      },
      getList(val) {
        if (val) {
          this.current = 1;
        }
        this.getTableHeight(360)
        this.$request(getConfigList(this.current, this.size), this.searchForm).then((res) => {
          if (res.code === 0) {
            let data = res.data;
            this.tableData = data.records;
            this.total = Number(data.total);
          }
        });
      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },

      // 推荐、上架、删除
      changeState(val, bool) {
        let text;
        if (!bool) {
          text = "确定删除？";
        } else if (bool == 1) {
          text = val.status == 0 ? "确定下架？" : "确定上架？";
        }
        val.bool = bool;
        this.$util.isSwal(text, this.changeStateSubmit, val);
      },
      changeStateSubmit(val) {
        this.$request(sysConfigDel, {
          id: val.id
        }).then((res) => {
          if (res.code === 0) {
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      //   编辑排序
      editSort() {
        let ids = [];
        let order = [];
        this.tableData.find((item) => {
          ids.push(item.id);
          order.push(item.orderNum);
        });
        this.$request(batchOrderNews, {
          ids,
          order
        }).then((res) => {
          if (res.code === "200") {
            this.showBtn = false;
            this.$util.message("suc", "操作成功");
            this.getList();
          }
        });
      },
      //   新增科普音频-弹框
      add(val) {
        this.loading = false
        this.addForm = {
          mchId: "",
          value: "",
          adaptorType: 4,
          type: 2 //1:直播;2:咨询热线
        };
        if (this.$refs.addForm) {
          this.$refs.addForm.resetFields();
        }
        this.edit = val;
        this.addDialog = true;
        if (val) {
          this.getInfo(val);
        }
      },
      // 平台列表
      getMerchantList(val) {
        this.$request(merchantList).then((res) => {
          if (res.code === 0) {
            this.maList = res.data;
          }
        });
      },
      // 查看详情
      getInfo(val) {
        this.$request(configInfo, {
          id: val
        }).then((res) => {
          if (res.code === 0) {
            this.addForm = res.data;
          }
        });
      },
      //   新增科普音频-上传接口
      addSubmit(val) {
        let url = this.edit ? configUpdate : configSave;
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.$request(url, this.addForm).then((res) => {
              if (res.code === 0) {
                this.addDialog = false;
                this.$util.message("suc", "操作成功");
                this.getList();
              }
            });
          }
        });
      },



      showState(val) {
        switch (val) {
          case 0:
            return "上架";
          case 1:
            return "下架";
          case 2:
            return "待上架";
        }
      },
      stateClass(val) {
        switch (val) {
          case 0:
            return "green_dot";
          case 1:
            return "grey_dot";
          case 2:
            return "yellow_dot";
        }
      },
    },
  };
</script>

<style lang="less" scoped>
  .consultHotline {
    .add {
      padding-top: 3px;
      margin-right: 24px;
    }

    /deep/.el-textarea__inner {
      padding: 5px 50px 5px 15px;
    }
  }
</style>